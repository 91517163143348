import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { evaluate } from 'mathjs'
import Header from '../components/shared/Header'
import Card from '../components/shared/Card'
import Inputbox from '../components/shared/Inputbox'
import PrimaryButton from '../components/shared/PrimaryButton'
import AccountContext from '../context/AccountContext'
import UserContext from '../context/UserContext'
import SecondaryButton from '../components/shared/SecondaryButton'
import Checkbox from '../components/shared/Checkbox'
import { LargerFont } from '../components/shared/Constants'
import RoleContext from '../context/RoleContext'
import DataDependencyContext from '../context/DataDependencyContext'
import IconLabel from '../components/shared/IconLabel'
import { FaCheck } from 'react-icons/fa'

function AccountsEditPage() {
  const {findAccount, updateAccountDetails, updateAccountSnapshot} = useContext(AccountContext)
  const {role, userId, colorTheme} = useContext(UserContext)
  const {checkPermission} = useContext(RoleContext)
  const {isQuickDataLoaded} = useContext(DataDependencyContext)


  const [account, setAccount] = useState({})
  const [snapshotList, setSnapshotList] = useState([])
  const [formDetailsData, setFormDetailsData] = useState({})
  const [formSnapshotData, setFormSnapshotData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const [latestSnapshotDate, setLatestSnapshotDate] = useState('')

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const suggestedSnapshotDate = _calculateSuggestedSnapshotDate()
    if (isQuickDataLoaded) {
      if (checkPermission(role, 'edit-account')) {
        const account = findAccount(params.id)
        setAccount(account)
        setFormDetailsData({
          name: account.name,
          institution: account.institution,
          currency: account.currency,
          country: account.country,
          note: account.note ?? "",
          is_cash: account.is_cash,
          is_investment: account.is_investment,
          is_income: account.is_income,
          is_expense: account.is_expense,
          is_active: account.is_active
        })

        let latestSnapshotData = {
          id: "",
          snapshot_date: suggestedSnapshotDate,
          total_amount: 0.0,
          pending_amount: 0.0
        }
        let sortedSnapshotList = []
        if (account.accountSnapshot_v2 && account.accountSnapshot_v2.length > 0) {
          sortedSnapshotList = account.accountSnapshot_v2.toSorted((a, b) => {
            return -1 * (a.snapshot_date.localeCompare(b.snapshot_date))
          })
          latestSnapshotData = {
            ...latestSnapshotData,
            id: sortedSnapshotList[0].id,
            //snapshot_date: sortedSnapshotList[0].snapshot_date,
            total_amount: sortedSnapshotList[0].total_amount,
            pending_amount: sortedSnapshotList[0].pending_amount
          }
          setLatestSnapshotDate(sortedSnapshotList[0].snapshot_date)
        }
        setSnapshotList(sortedSnapshotList)
        setFormSnapshotData(latestSnapshotData)
        setIsLoading(false)
      } else {
        toast.error('ERROR: Permission denied')
        navigate('/accounts')
      }
    }
  }, [isQuickDataLoaded])

  const _calculateSuggestedSnapshotDate = () => {
    const today = moment()
    if (today.date() >= 15) {
      return moment().date(15).format('YYYY-MM-DD')
    } else {
      return moment().date(1).subtract(1, 'days').format('YYYY-MM-DD')
    }
  }

  const _getSnapshotOperation = () => {   
    return formSnapshotData.snapshot_date === latestSnapshotDate ? "Edit" : "Add"
  }

  const onUpdateDetails = async (e) => {
    e.preventDefault()

    const result = await updateAccountDetails(account.id, formDetailsData)
    if (result) {
      toast.success('Success: Account details update')
      navigate('/accounts')
    } else {
      toast.error('ERROR Cannot update account details')
    }
  }

  const onUpdateSnapshot = async (e) => {
    e.preventDefault()

    const today = moment().format('YYYY-MM-DD')
    const operation = _getSnapshotOperation()
    const id = operation === "Add" ? uuidv4() : formSnapshotData.id
    // need to convert any number to string as evaluate only accepts string
    const newSnapshot = {
      total_amount: Math.round(evaluate(formSnapshotData.total_amount.toString()) * 100) / 100,
      pending_amount: Math.round(evaluate(formSnapshotData.pending_amount.toString()) * 100) / 100,
      id: id,
      account_id: account.id,
      snapshot_date: formSnapshotData.snapshot_date,
      update_date: today,
      user_id: userId
    }

    const result = await updateAccountSnapshot(operation, newSnapshot)

    if (result) {
      toast.success('Success: Update account snapshot')
      navigate('/accounts')
    } else {
      toast.error('ERROR Cannot update account snapshot')
    }
  }

  const onMutateSnapshot = (e) => {
    setFormSnapshotData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  const onMutateDetails = (e) => {
    // Check for boolean
    let boolean = null
    if (e.target.value === 'true') {
      boolean = true
    }
    if (e.target.value === 'false') {
      boolean = false
    }
    // For others, e.g. text and number
    if (!e.target.files) {
      setFormDetailsData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value
      }))
    }
  }

  const onToggleCheckbox = (e) => {
    const newValue = formDetailsData[e.target.id];
    setFormDetailsData((prevState) => ({
      ...prevState,
      [e.target.id]: !newValue
    }))
  }

  if (isLoading) {
    return (<h1>Loading...</h1>)
  }

  return (
    <>
      <Header title="Edit Account" />
      <div className={`mb-4 font-bold ${LargerFont}`}>{account.institution} - {account.name} ({account.currency})</div>

      <Card className='mb-4'>
      <div className={`mb-4 ${LargerFont}`}>Edit Snapshot</div>
        <Inputbox 
          placeholder='Total Amount' 
          type='text' 
          id='total_amount'
          name='total_amount' 
          value={formSnapshotData.total_amount}
          onChange={onMutateSnapshot}
          annotation="Accepts Math Expression, e.g. 1+2-3"
        />
        <Inputbox 
          placeholder='Pending Amount' 
          type='text' 
          id='pending_amount'
          name='pending_amount' 
          value={formSnapshotData.pending_amount}
          onChange={onMutateSnapshot}
          annotation="Accepts Math Expression, e.g. 1+2-3"
        />
        <Inputbox 
          name='snapshot_date'
          id='snapshot_date'
          type='date'
          placeholder='Snapshot Date'
          value={formSnapshotData.snapshot_date}
          onChange={onMutateSnapshot}   
        />
        <div className="flex items-center">
          <PrimaryButton onClick={onUpdateSnapshot} className='mt-2 mr-4'>
            <IconLabel
              left={<FaCheck />}
              right='Update Snapshot'
            />
          </PrimaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/accounts")}>Cancel</SecondaryButton>  
        </div>
        
        <div className="mb-8"></div>

        <div className={`mb-2 ${LargerFont}`}>Snapshot History</div>
        <div className="">
          <table>
            <tbody>
              {snapshotList && snapshotList.length > 0 && snapshotList.map((item, index) => (
                <tr key={index}>
                  <td className='pr-4 pb-1'>{item.snapshot_date}</td>
                  <td className='pr-4 pb-1'>Total: {item.total_amount}</td>
                  <td className='pr-4 pb-1'>Pending: {item.pending_amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      <Card className='mb-4'>
        <div className={`mb-4 ${LargerFont}`}>Edit Details</div>

        <Inputbox name='name' id='name' placeholder='Name' value={formDetailsData.name} onChange={onMutateDetails}/>
        <Inputbox name='institution' id='institution' placeholder='Institution' value={formDetailsData.institution} onChange={onMutateDetails}/>
        <Inputbox name='currency' id='currency' placeholder='Currency' value={formDetailsData.currency} onChange={onMutateDetails} annotation={`Only supported: HKD, USD, AUD, GBP, EUR`}/>
        <Inputbox name='country' id='country' placeholder='Country' value={formDetailsData.country} onChange={onMutateDetails} annotation={`Only Supported: Hong Kong, Australia, United Kingdom`}/>

        <div className="mb-2">Account Type</div>
        <Checkbox
          label='Is Active Account'
          name='is_active'
          id='is_active'
          checked={formDetailsData.is_active}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Cash'
          name='is_cash'
          id='is_cash'
          checked={formDetailsData.is_cash}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Investment'
          name='is_investment'
          id='is_investment'
          checked={formDetailsData.is_investment}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Income'
          name='is_income'
          id='is_income'
          checked={formDetailsData.is_income}
          onChange={onToggleCheckbox}
        />
        <Checkbox
          label='Expense'
          name='is_expense'
          id='is_expense'
          checked={formDetailsData.is_expense}
          onChange={onToggleCheckbox}
        />
        

        <div className="mb-4"></div>
        <div className="mb-2">Note</div>
        <textarea 
          name="note" 
          id="note" 
          rows="20" 
          value={formDetailsData.note} 
          onChange={onMutateDetails} 
          className={`${colorTheme.default_bg_color} border-2 ${colorTheme.secondary_border_color} focus:outline-none focus:${colorTheme.primary_border_color} px-3 py-2 w-full rounded`} />
        
        <div className="mb-4"></div>
        <div className="flex items-center">        
          <PrimaryButton onClick={onUpdateDetails}>
            <IconLabel
              left={<FaCheck />}
              right='Update Details'
            />
          </PrimaryButton>
          <SecondaryButton className='ml-auto' onClick={e => navigate("/accounts")}>Cancel</SecondaryButton>  
        </div>  
      </Card>
    </>
  )
}

export default AccountsEditPage